import React from 'react'

import PropTypes from 'prop-types'

import styles from './feature-card_mexico.module.css'

const FeatureCardMexico = (props) => {
  return (
    <div
      className={` ${styles['feature-card']} ${styles[props.rootClassName]} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['container']}>
        <h2 className={styles['text']}>{props.title}</h2>
        <span>{props.description}</span>
      </div>
    </div>
  )
}

FeatureCardMexico.defaultProps = {
  title: 'Mexico',
  image_alt: 'image',
  rootClassName: '',
  description: '• Mexico City',
  image_src: '/playground_assets/mexico.svg',
}

FeatureCardMexico.propTypes = {
  title: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
  description: PropTypes.string,
  image_src: PropTypes.string,
}

export default FeatureCardMexico
