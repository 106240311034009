import React from 'react'

import PropTypes from 'prop-types'

import styles from './feature-card_poland.module.css'

const FeatureCardPoland = (props) => {
  return (
    <div
      className={` ${styles['feature-card']} ${styles[props.rootClassName]} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['container']}>
        <h2 className={styles['text']}>{props.title}</h2>
        <span>{props.description}</span>
      </div>
    </div>
  )
}

FeatureCardPoland.defaultProps = {
  title: 'Poland',
  image_src: '/playground_assets/poland.svg',
  rootClassName: '',
  image_alt: 'image',
  description: '• Warsaw',
}

FeatureCardPoland.propTypes = {
  title: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  description: PropTypes.string,
}

export default FeatureCardPoland
