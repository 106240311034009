import React from 'react'

import PropTypes from 'prop-types'

import styles from './feature-card_u-s-a.module.css'

const FeatureCardUSA = (props) => {
  return (
    <div
      className={` ${styles['feature-card']} ${styles[props.rootClassName]} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['container']}>
        <h2 className={styles['text']}>{props.title}</h2>
        <span>{props.description}</span>
        <span>{props.text}</span>
        <span>{props.text1}</span>
        <span>{props.text2}</span>
        <span>{props.text3}</span>
        <span>{props.text4}</span>
        <span>{props.text5}</span>
        <span>{props.text6}</span>
      </div>
    </div>
  )
}

FeatureCardUSA.defaultProps = {
  image_alt: 'image',
  text3: '• Los Angeles, California',
  text6: '• Silicon Valley, California',
  text5: '• Seattle, Washington',
  text4: '• Miami, Florida',
  image_src: '/playground_assets/us.svg',
  description: '• New York / New Jersey',
  text1: '• Chicago, Illinois',
  text: '• Atlanta, Georgia',
  text2: '• Dallas, Texas',
  rootClassName: '',
  title: 'United States',
}

FeatureCardUSA.propTypes = {
  image_alt: PropTypes.string,
  text3: PropTypes.string,
  text6: PropTypes.string,
  text5: PropTypes.string,
  text4: PropTypes.string,
  image_src: PropTypes.string,
  description: PropTypes.string,
  text1: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  rootClassName: PropTypes.string,
  title: PropTypes.string,
}

export default FeatureCardUSA
