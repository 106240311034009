import React from 'react'
import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

import FeatureCardUSA from '../components/feature-card_u-s-a'
import FeatureCardAustralia from '../components/feature-card_australia'
import FeatureCardUK from '../components/feature-card_u-k'
import FeatureCardCanada from '../components/feature-card_canada'
import FeatureCardGermany from '../components/feature-card_germany'
import FeatureCardJapan from '../components/feature-card_japan'
import FeatureCardSouthKorea from '../components/feature-card_south-korea'
import FeatureCardSpain from '../components/feature-card_spain'
import FeatureCardBrazil from '../components/feature-card_brazil'
import FeatureCardMexico from '../components/feature-card_mexico'
import FeatureCardNetherlands from '../components/feature-card_netherlands'
import FeatureCardPoland from '../components/feature-card_poland'
import FeatureCardSingapore from '../components/feature-card_singapore'
import FeatureCardSweden from '../components/feature-card_sweden'
import FeatureCardFrance from '../components/feature-card_france'
import FeatureCardIndia from '../components/feature-card_india'
import projectStyles from '../style.module.css'
import styles from './index.module.css'

const Home = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Covert_Bear</title>
        <meta property="og:title" content="Covert_Bear" />
      </Helmet>
      <div data-role="Header" className={styles['navbar-container']}>
        <div className={styles['navbar']}>
          <div className={styles['left-side']}>
            <a href="#home" className={styles['link']}>
              <img
                alt="image"
                src="/playground_assets/icon-white-200h.png"
                className={styles['image']}
              />
            </a>
            <div data-type="BurgerMenu" className={styles['burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className={styles['links-container']}>
              <a
                href="#home"
                className={` ${styles['link01']} ${projectStyles['anchor']} `}
              >
                Home
              </a>
              <a
                href="#about"
                className={` ${styles['link02']} ${projectStyles['anchor']} `}
              >
                About
              </a>
              <a
                href="#services"
                className={` ${styles['link03']} ${projectStyles['anchor']} `}
              >
                Services
              </a>
              <a
                href="#locations"
                className={` ${styles['link04']} ${projectStyles['anchor']} `}
              >
                Locations
              </a>
              <Link
                to="/pricing"
                className={` ${styles['link05']} ${projectStyles['anchor']} `}
              >
                Pricing
              </Link>
              <Link
                to="/v-p-n"
                className={` ${styles['link06']} ${projectStyles['anchor']} `}
              >
                VPN
              </Link>
              <Link
                to="/windows-r-d-p"
                className={` ${styles['link07']} ${projectStyles['anchor']} `}
              >
                Windows RDP
              </Link>
              <Link
                to="/linux-r-d-p"
                className={` ${styles['link08']} ${projectStyles['anchor']} `}
              >
                Linux RDP
              </Link>
            </div>
          </div>
          <div className={styles['right-side']}>
            <Link
              to="/contact1"
              className={` ${styles['cta-btn']} ${projectStyles['anchor']} ${projectStyles['button']} `}
            >
              Contact Us
            </Link>
          </div>
          <div data-type="MobileMenu" className={styles['mobile-menu']}>
            <div className={styles['container1']}>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className={styles['image1']}
              />
              <div data-type="CloseMobileMenu" className={styles['close-menu']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className={styles['links-container1']}>
              <a
                href="#resources"
                className={` ${styles['link09']} ${projectStyles['anchor']} `}
              >
                Resources
              </a>
              <span
                className={` ${styles['link10']} ${projectStyles['anchor']} `}
              >
                Inspiration
              </span>
              <a
                href="#process"
                className={` ${styles['link11']} ${projectStyles['anchor']} `}
              >
                Process
              </a>
              <span
                className={` ${styles['link12']} ${projectStyles['anchor']} `}
              >
                Our story
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="home" className={styles['hero']}>
        <div className={styles['content-container']}>
          <div className={styles['hero-text']}>
            <h1
              className={` ${styles['heading']} ${projectStyles['section-heading']} `}
            >
              Covert Bear
            </h1>
            <div className={styles['separator']}></div>
            <span
              className={` ${styles['text']} ${projectStyles['section-text']} `}
            >
              <span>Virtual Private Network (VPN)</span>
              <br></br>
              <span>Remote Desktop Portal (RDP)</span>
            </span>
            <span
              className={` ${styles['text03']} ${projectStyles['section-text']} `}
            >
              <span>We&apos;ve got your back</span>
            </span>
            <Link
              to="/contact1"
              className={` ${styles['cta-btn1']} ${projectStyles['anchor']} ${projectStyles['button']} `}
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
      <div id="about" className={styles['about-us']}>
        <div className={styles['container2']}>
          <h1 className={styles['text05']}>Why Covert Bear?</h1>
          <span className={styles['text06']}>
            <span className={styles['text07']}>
              Privacy
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              and
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className={styles['text09']}>
              Security
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>are what drives us.</span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              The Internet is a chaotic ocean of data and services, and many of
              us have been navigating its waters haphazardly for far too long.
              Well we&apos;ve had enough!
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              Covert Bear can&apos;t control your ship on that ocean, but we can
              light the way and provide safe routes for you to get to port!
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Without us,
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className={styles['text17']}>Web Servers</span>
            <span>
              {' '}
              and other
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className={styles['text19']}>
              Users
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              out there can see your
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className={styles['text21']}>location</span>
            <span>, they can even check</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className={styles['text24']}>
              what you&apos;re sending and where.
            </span>
            <span>
              {' '}
              We humans have made so many advancements in technology but lost
              our privacy somewhere along the way.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Check out what services we provide below to find what fits you
              just right.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Contact us when you&apos;re ready to surf the web safely, or you
              can ask for more details or even just reach out to say hello.
            </span>
          </span>
          <div className={styles['btn-group']}>
            <Link
              to="/contact1"
              className={` ${styles['navlink']} ${projectStyles['button']} `}
            >
              <span className={styles['text30']}>Get Started</span>
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/stormy-seas-1200w.jpg"
          className={styles['image2']}
        />
      </div>
      <div className={styles['section-separator']}></div>
      <div id="services" className={styles['services']}>
        <div className={styles['heading-container']}>
          <h1
            className={` ${styles['text31']} ${projectStyles['section-heading']} `}
          >
            Services
          </h1>
          <div className={styles['separator1']}></div>
          <span
            className={` ${styles['text32']} ${projectStyles['section-text']} `}
          >
            What do you need?
          </span>
        </div>
        <div className={styles['cards-container']}>
          <div className={styles['service-card']}>
            <img
              alt="image"
              src="/playground_assets/vpn-200h.jpg"
              className={styles['image3']}
            />
            <h3
              className={` ${styles['text33']} ${projectStyles['card-heading']} `}
            >
              Dedicated VPN
            </h3>
            <span
              className={` ${styles['text34']} ${projectStyles['card-text']} `}
            >
              <span>Virtual Private Network Server</span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                Route all of your traffic through your very own Dedicated VPN
                Server at one of our many offered locations. No more giving away
                your location to strangers, no more sharing servers with
                bandwidth hogs, all your traffic encrypted for tight security.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span></span>
              <br></br>
              <span>Surf the internet your way.</span>
            </span>
            <Link
              to="/v-p-n"
              className={` ${styles['navlink1']} ${projectStyles['button']} `}
            >
              <span className={styles['text41']}>Learn More</span>
            </Link>
          </div>
          <div className={styles['service-card1']}>
            <img
              alt="image"
              src="/playground_assets/windows-200h.jpg"
              className={styles['image4']}
            />
            <h3
              className={` ${styles['text42']} ${projectStyles['card-heading']} `}
            >
              Windows RDP
            </h3>
            <span
              className={` ${styles['text43']} ${projectStyles['card-text']} `}
            >
              <span>Remote Desktop Portal</span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                Your very own personal Windows computer away from home. Choose
                from any of our offered locations, gain access to products and
                services only available to the locals!
              </span>
              <br></br>
              <span>Protect your own machine from the Internet.</span>
            </span>
            <Link
              to="/windows-r-d-p"
              className={` ${styles['navlink2']} ${projectStyles['button']} `}
            >
              <span className={styles['text49']}>Learn More</span>
            </Link>
          </div>
          <div className={styles['service-card2']}>
            <img
              alt="image"
              src="/playground_assets/linux-200w.jpg"
              className={styles['image5']}
            />
            <h3
              className={` ${styles['text50']} ${projectStyles['card-heading']} `}
            >
              Linux RDP
            </h3>
            <span
              className={` ${styles['text51']} ${projectStyles['card-text']} `}
            >
              <span>Remote Desktop Portal</span>
              <br></br>
              <span></span>
              <br></br>
              <span>
                Perfect for those who simply want to surf the web. Lightweight,
                cheaper alternative to Windows.
              </span>
            </span>
            <Link
              to="/linux-r-d-p"
              className={` ${styles['navlink3']} ${projectStyles['button']} `}
            >
              <span className={styles['text56']}>Learn More</span>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles['section-separator1']}></div>
      <div id="locations" className={styles['locations']}>
        <div className={styles['features']}>
          <h1 className={styles['text57']}>
            <span>Locations</span>
          </h1>
          <div className={styles['separator2']}></div>
          <div className={styles['container3']}>
            <FeatureCardUSA rootClassName="root-class-name4"></FeatureCardUSA>
            <FeatureCardAustralia rootClassName="root-class-name"></FeatureCardAustralia>
            <FeatureCardUK rootClassName="root-class-name"></FeatureCardUK>
            <FeatureCardCanada rootClassName="root-class-name"></FeatureCardCanada>
            <FeatureCardGermany rootClassName="root-class-name"></FeatureCardGermany>
            <FeatureCardJapan rootClassName="root-class-name"></FeatureCardJapan>
            <FeatureCardSouthKorea rootClassName="root-class-name"></FeatureCardSouthKorea>
            <FeatureCardSpain rootClassName="root-class-name"></FeatureCardSpain>
            <FeatureCardBrazil rootClassName="root-class-name"></FeatureCardBrazil>
            <FeatureCardMexico rootClassName="root-class-name1"></FeatureCardMexico>
            <FeatureCardNetherlands rootClassName="root-class-name"></FeatureCardNetherlands>
            <FeatureCardPoland rootClassName="root-class-name"></FeatureCardPoland>
            <FeatureCardSingapore rootClassName="root-class-name"></FeatureCardSingapore>
            <FeatureCardSweden rootClassName="root-class-name"></FeatureCardSweden>
            <FeatureCardFrance rootClassName="root-class-name"></FeatureCardFrance>
            <FeatureCardIndia rootClassName="root-class-name"></FeatureCardIndia>
          </div>
        </div>
      </div>
      <div className={styles['footer-container']}>
        <div className={styles['footer']}>
          <div className={styles['copyright-container']}>
            <span
              className={` ${styles['link13']} ${projectStyles['anchor']} `}
            >
              <span>© Copyright, 2021</span>
              <br></br>
              <span>Covert Bear, All Rights Reserved</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
