import React from 'react'

import PropTypes from 'prop-types'

import styles from './feature-card_india.module.css'

const FeatureCardIndia = (props) => {
  return (
    <div
      className={` ${styles['feature-card']} ${styles[props.rootClassName]} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['container']}>
        <h2 className={styles['text']}>{props.title}</h2>
        <span>{props.description}</span>
      </div>
    </div>
  )
}

FeatureCardIndia.defaultProps = {
  description: '• Mumbai',
  image_alt: 'image',
  title: 'India',
  rootClassName: '',
  image_src: '/playground_assets/india.svg',
}

FeatureCardIndia.propTypes = {
  description: PropTypes.string,
  image_alt: PropTypes.string,
  title: PropTypes.string,
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
}

export default FeatureCardIndia
