import React from 'react'

import PropTypes from 'prop-types'

import styles from './feature-card_canada.module.css'

const FeatureCardCanada = (props) => {
  return (
    <div
      className={` ${styles['feature-card']} ${styles[props.rootClassName]} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['container']}>
        <h2 className={styles['text']}>{props.title}</h2>
        <span>{props.description}</span>
      </div>
    </div>
  )
}

FeatureCardCanada.defaultProps = {
  rootClassName: '',
  image_alt: 'image',
  description: '• Toronto',
  title: 'Canada',
  image_src: '/playground_assets/canada.svg',
}

FeatureCardCanada.propTypes = {
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  image_src: PropTypes.string,
}

export default FeatureCardCanada
