import React from 'react'

import PropTypes from 'prop-types'

import styles from './feature-card_france.module.css'

const FeatureCardFrance = (props) => {
  return (
    <div
      className={` ${styles['feature-card']} ${styles[props.rootClassName]} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['container']}>
        <h2 className={styles['text']}>{props.title}</h2>
        <span>{props.description}</span>
      </div>
    </div>
  )
}

FeatureCardFrance.defaultProps = {
  image_alt: 'image',
  image_src: '/playground_assets/france.svg',
  rootClassName: '',
  title: 'France',
  description: '• Paris',
}

FeatureCardFrance.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default FeatureCardFrance
